<template>
  <div>
    <p class="p-form-label-with-margin-bold">Status</p>
    <div>
      <traffic-lights-buttons v-model="safetyEvaluation.status"
                              :options="options"
                              labeled-lights
                              size="md"/>
    </div>
    <p class="p-form-label-with-margin-bold">Comments for Reviewers and Management</p>
    <b-form-textarea id="comments_review"
                     v-model="safetyEvaluation.comments_review"
                     rows="5"/>
    <p class="p-form-label-with-margin-bold">
      Comments FOR USER (also visible to Reviewers, Management and Scientists)
    </p>
    <b-form-textarea id="comments_user"
                     v-model="safetyEvaluation.comments_user"
                     rows="5"/>
    <b-button class="ma-1 mt-3 right"
              variant="info"
              @click="saveEvaluation">
      SAVE
    </b-button>
  </div>
</template>

<script>
  import { envMixin } from '@/mixins/environment';
  import trafficLightsOptions from '@/json/trafficLightsAcceptanceMarkSafety';
  import TrafficLightsButtons from '@/components/traffic_lights_buttons';

  export default {
    name: 'SafetyForm',
    components: { TrafficLightsButtons },
    mixins: [envMixin],
    props: {
      proposalId: {
        type: String,
        required: true,
      },
      sampleIndex: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        safetyEvaluation: JSON.parse(JSON.stringify(this.value)),
        options: trafficLightsOptions,
      };
    },
    watch: {
      value: {
        handler() {
          this.safetyEvaluation = JSON.parse(JSON.stringify(this.value));
        },
        deep: true,
      },
    },
    methods: {
      async saveEvaluation() {
        try {
          await this.axios.post(
            `/documents/${this.proposalId}/safety/${this.environment}`,
            {
              index: this.sampleIndex,
              content: this.safetyEvaluation,
            },
          );
          this.$set(this.safetyEvaluation, 'states', { name: 'draft' });
          this.$emit('input', this.safetyEvaluation);
          this.$notify({ type: 'success', title: 'Saved successfully!' });
          this.close_modal_emit();
        } catch {
          this.$notify({ type: 'error', title: 'Error during saving' });
        }
      },
    },
  };
</script>

<style lang="scss"
       scoped>
</style>
