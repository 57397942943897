<template>
  <div>
    <b-tabs v-model="selectedTab"
            card>

      <b-tab active
             title="Standard proposals">
        <b-row align-h="between"
               class="container-fluid pb-3"
               no-gutters>

          <b-col cols="auto">
            <b-row id="filters">
              <b-col cols="auto">
                <b-button :pressed.sync="showFilter"
                          class="mb-2 mr-2">
                  FILTER
                </b-button>
              </b-col>

              <b-col cols="auto">
                <keep-alive>
                  <call-filter :id="id"
                               ref="call_filter"
                               v-model="proposals"
                               :loading="loading"
                               all-calls
                               @load_data="loadDataFor"
                               @selected_call_change="call_change"
                               @update_proposals="refresh_proposals_filter"/>
                </keep-alive>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
        <b-table :current-page="currentPage"
                 :fields="evaluationHelpers.evaluationTableFields"
                 :items="proposals"
                 :per-page="perPage"
                 class="table-responsive"
                 head-variant="light"
                 hover
                 responsive
                 striped
                 thead-class="text-center"
                 @row-clicked="setShowFinalSubmit">

          <template v-slot:top-row="{fields}">
            <proposal-filter :id="id"
                             v-model="proposals"
                             :fields="fields"
                             :not_include_key="['actions', 'samples', 'ordinalNumber']"
                             :selected_call="selectedCall"/>
          </template>

          <template v-slot:cell(ordinalNumber)="row">
            <base-column-ordinal-number v-bind="{index: row.index, perPage, currentPage}"/>
          </template>

          <template v-slot:cell(samples[0].risk)="row">
            {{ getSampleRisk(row.item.samples) }}
          </template>

          <template v-slot:cell(samples[0].sample_safety)="row">
            {{ getSampleSafety(row.item.samples) }}
          </template>

          <template v-slot:cell(samples[0].sample_safety_radioactive)="row">
            <div v-if="hasProperty(row.item.samples[0], 'sample_safety_radioactive')">
              <b-form-checkbox v-model="row.item.samples[0].sample_safety_radioactive"
                               :disabled="true"/>
            </div>
            <div v-else>
              <b-form-checkbox :disabled="true"
                               v-bind:value="false"/>
            </div>
          </template>

          <template v-slot:cell(samples[0].safety_evaluation.status)="row">
            <p v-if="isProposalCeric(row.item, evaluation)">
              {{ row.item.samples[0].safety_evaluation.status }}
            </p>
            <traffic-lights-buttons v-else
                                    v-model="row.item.samples[0].safety_evaluation.status"
                                    :options="trafficLightsOptions"
                                    disabled
                                    stacked/>
          </template>
          <template v-slot:cell(samples[0].radiation_evaluation.status)="row">
            <p v-if="isProposalCeric(row.item, evaluation)">
              {{ row.item.samples[0].radiation_evaluation.status }}
            </p>
            <traffic-lights-buttons v-else
                                    v-model="row.item.samples[0].radiation_evaluation.status"
                                    :options="trafficLightsOptions"
                                    disabled
                                    stacked/>
          </template>

          <template v-slot:cell(samples[0].safety_evaluation.states.name)="row">
            {{ row.item.samples[0].safety_evaluation.states.name }}
          </template>
          <template v-slot:cell(samples[0].radiation_evaluation.states.name)="row">
            {{ row.item.samples[0].radiation_evaluation.states.name }}
          </template>

          <template v-slot:cell(additional_sample_declaration.safety_evaluation.status)="row">
            <traffic-lights-buttons v-if="isAsdEvaluationSubmitted(row.item)"
                                    v-model="row.item.additional_sample_declaration.safety_evaluation.status"
                                    :options="trafficLightsOptions"
                                    disabled
                                    stacked/>
          </template>
          <template v-slot:cell(additional_sample_declaration.radiation_evaluation.status)="row">
            <traffic-lights-buttons v-if="isAsdEvaluationSubmitted(row.item)"
                                    v-model="row.item.additional_sample_declaration.radiation_evaluation.status"
                                    :options="trafficLightsOptions"
                                    disabled
                                    stacked/>
          </template>

          <template v-slot:cell(actions)="row">
            <div @click="setShowFinalSubmit(row.item, 0, $event)"
                 @keyup.down="setShowFinalSubmit(row.item, 0, $event)">
              <action-download-pdf :proposal="row.item"/>

              <template v-if="isSynchrotron">
                <action-download-additional-sample-declaration-pdf v-if="isAdditionalSampleDeclaration(row.item)"
                                                                   :proposal="(row.item)"/>
                <base-icon-button-edit v-if="isEditionAllowed(row.item.samples[0])"
                                       v-b-modal.safetyRadiationModal
                                       title="Evaluate sample"
                                       @click="evaluateSample(row.item, 0)"/>

                <base-action-expand-details :b-table-row="row"/>

                <base-double-click-submit-button v-if="isEditionAllowed(row.item.samples[0])"
                                                 :btn-options="submitBtnOptions"
                                                 :is-confirmation="isFinalSubmit(row.item, 0)"
                                                 class="d-block ml-auto"
                                                 @click="submitEvaluation(row.item, row.item.samples[0])"/>
              </template>
              <template v-else>
                <base-action-expand-details :b-table-row="row"/>
              </template>

            </div>
          </template>

          <template v-slot:row-details="row">
            <b-card class="ma-4">
              <b-table id="samplesTable"
                       :fields="evaluationHelpers.evaluationTableFieldsSamples"
                       :items="row.item.samples"
                       :striped="false"
                       class="table-responsive"
                       hover
                       responsive
                       @row-clicked="setShowFinalSubmit">

                <template v-slot:cell(safety_evaluation.status)="samples">
                  <traffic-lights-buttons v-model="samples.item.safety_evaluation.status"
                                          :options="trafficLightsOptions"
                                          disabled
                                          stacked/>
                </template>
                <template v-slot:cell(radiation_evaluation.status)="samples">
                  <traffic-lights-buttons v-model="samples.item.radiation_evaluation.status"
                                          :options="trafficLightsOptions"
                                          disabled
                                          stacked/>
                </template>

                <template v-if="!isSynchrotron" v-slot:cell(actions)="samples">
                  <div @click="setShowFinalSubmit(row.item, samples.index, $event)"
                       @keyup.down="setShowFinalSubmit(row.item, samples.index, $event)">
                    <template v-if="isEditionAllowed(samples.item)">
                      <base-icon-button-edit v-b-modal.safetyRadiationModal
                                             title="Evaluate sample"
                                             @click="evaluateSample(row.item, samples.index)"/>
                      <base-double-click-submit-button :btn-options="submitBtnOptions"
                                                       :is-confirmation="isFinalSubmit(row.item, samples.index)"
                                                       @click="submitEvaluation(row.item, samples)"/>
                    </template>
                  </div>
                </template>

              </b-table>
            </b-card>
          </template>
        </b-table>
        <b-table-footer v-model="loading"
                        :current-page="currentPage"
                        :data_length="proposals.length"
                        :per-page="perPage"
                        @change_page="changePage"/>
        <b-modal id="safetyRadiationModal"
                 :ref="modal_ref"
                 :title="modalTitle"
                 bg-variant="light"
                 header-bg-variant="dark"
                 header-text-variant="white"
                 hide-footer
                 lazy
                 size="lg"
                 @hidden="closeModal">
          <safety-radiation-modal v-model="sample"
                                  :fields="evaluationHelpers.evaluationModalFields"
                                  :isSafetyEvaluation="evaluationType === 'safety'"
                                  :proposal-id="proposalId"
                                  :sample-index="sampleIndex"
                                  @closeModal="close_modal_slot"/>
        </b-modal>
      </b-tab>

      <b-tab v-if="isSynchrotron"
             title="Additional sample declaration">

        <additional-sample-evaluation-tab ref="asd_table"
                                          :evaluationType="evaluationType"
                                          :is-user-editor="isUserEditor"
                                          @update-proposal="updateProposal($event)"/>

      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
  import ActionDownloadAdditionalSampleDeclarationPdf
    from '@/components/actions/ActionDownloadAdditionalSampleDeclarationPdf';
  import ActionDownloadPdf from '@/components/actions/ActionDownloadPdf';
  import AdditionalSampleEvaluationTab from '@/components/evaluation/AdditionalSampleEvaluationTab';
  import BaseActionExpandDetails from '@/components/actions/BaseActionExpandDetails';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import BaseDoubleClickSubmitButton from '@/components/baseComponents/BaseDoubleClickSubmitButton';
  import BaseIconButtonEdit from '@/components/baseComponents/baseIcons/BaseIconButtonEdit';
  import bTableFooter from '@/components/b_table_footer';
  import callFilter from '@/components/call_filter';
  import SafetyRadiationModal from '@/components/evaluation/SafetyRadiationModal';
  import proposalFilter from '@/components/proposal_filter';
  import options from '@/json/evaluation';
  import submitBtnOptions from '@/json/doubleClickSubmitButton';
  import trafficLightsOptions from '@/json/trafficLightsAcceptanceMarkSafety';
  import { envMixin } from '@/mixins/environment';
  import TrafficLightsButtons from '@/components/traffic_lights_buttons';
  import { setDataInProposalsSafety, setDataInProposalsRadiation } from '@/helpers/safetyRadiationEvaluation';

  export default {
    name: 'SafetyRadiationDashboard',
    components: {
      AdditionalSampleEvaluationTab,
      ActionDownloadAdditionalSampleDeclarationPdf,
      BaseActionExpandDetails,
      BaseColumnOrdinalNumber,
      BaseDoubleClickSubmitButton,
      ActionDownloadPdf,
      BaseIconButtonEdit,
      SafetyRadiationModal,
      callFilter,
      proposalFilter,
      bTableFooter,
      TrafficLightsButtons,
    },
    mixins: [envMixin],
    props: {
      evaluationHelpers: {
        type: Object,
        required: true,
      },
      evaluationType: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        // necessary for proposals and calls filter feature
        id: 1,
        loading: false,
        proposals: [],
        proposalsWithAsd: [],
        selectedCall: '',
        // end

        // necessary for b-tabs and b-table feature
        selectedTab: 0,
        currentPage: 1,
        perPage: 20,
        // end

        // use only for visual effect and trigger event for filter component
        showFilter: true,

        // use to hide modal
        // eslint-disable-next-line camelcase
        modal_ref: 'modalRef',

        // necessary for editing and submitting evaluations
        proposalId: '',
        sampleIndex: 0,
        sample: {},
        options: options.options,
        finalSubmitProposalId: '',
        finalSubmitSampleIndex: '',
        submitBtnOptions,
        trafficLightsOptions,
        noDataText: '- (multiple samples)',
      };
    },
    computed: {
      evaluation() {
        return `${this.evaluationType}_evaluation`;
      },
      isSynchrotron() {
        return this.environment === 'synchrotron';
      },
      isUserEditor() {
        const permissions = this.$store.getters['login/getLoggedUserAttribute']('permissions') || [];
        return permissions.includes(this.evaluationHelpers.editorPermission);
      },
      modalTitle() {
        return `${this.evaluationType.charAt(0).toUpperCase()}${this.evaluationType.slice(1)} evaluation`;
      },
    },
    watch: {
      showFilter() {
        this.$emit('toggle_show');
      },
    },
    methods: {
      changePage(val) {
        this.currentPage = val;
      },
      closeModal() {
        const index = this.proposals.findIndex((el) => el._id.$oid === this.proposalId);
        if (index >= 0) {
          this.proposals[index].samples[this.sampleIndex] = this.sample;
        }
        if (this.environment === 'synchrotron') {
          this.$refs.asd_table.updateProposal(this.proposals[index]);
        }
        this.sample = {};
      },
      editable(sample) {
        if (sample[this.evaluation]) {
          if (sample[this.evaluation].states) {
            const values = ['submitted', 'deleted', '- (CERIC)'];
            return (!values.includes(sample[this.evaluation].states.name));
          }
        }
        return true;
      },
      evaluateSample(item, sampleIndex) {
        this.sampleIndex = sampleIndex;
        this.proposalId = item._id.$oid;
        this.sample = item.samples[sampleIndex];
      },
      getSampleRisk(samples) {
        return samples.length > 1 ? this.noDataText : samples[0].risk;
      },
      getSampleSafety(samples) {
        return samples.length > 1 ? this.noDataText : samples[0].sample_safety?.join(', ');
      },
      isAdditionalSampleDeclaration(item) {
        return item.additional_sample_declaration?.states?.name === 'submitted';
      },
      isAsdEvaluationSubmitted(item) {
        if (item.additional_sample_declaration?.[this.evaluation]) {
          return item.additional_sample_declaration[this.evaluation]?.states?.name === 'submitted';
        }
        return false;
      },
      isEditionAllowed(sample) {
        if (!this.isUserEditor) return false;
        return this.editable(sample);
      },
      isFinalSubmit(proposal, sampleIndex) {
        if (this.isSynchrotron === false) {
          return proposal._id.$oid === this.finalSubmitProposalId && sampleIndex === this.finalSubmitSampleIndex;
        }
        return proposal._id.$oid === this.finalSubmitProposalId;
      },
      isProposalCeric(proposal, evaluation) {
        return proposal.samples[0][evaluation].status === '- (CERIC)';
      },
      async loadDataFor(call) {
        const callParam = { call };
        this.loading = true;
        try {
          const response = await this.axios.get(
            `/documents/${this.evaluationType}/${this.environment}`,
            { params: callParam },
          );
          if (response.status !== 204) {
            this.proposals = this.evaluationType === 'safety'
              ? await setDataInProposalsSafety(response.data, this.environment)
              : await setDataInProposalsRadiation(response.data, this.environment);
          } else {
            this.proposals = [];
          }
          // update data in proposal-filter, call-filter component
          this.$emit('setup_proposal', this.proposals);
        } catch (error) {
          if (error.response.data) {
            this.$notify({ type: 'error', title: error.response.data });
          } else {
            this.$notify({ type: 'error', title: 'Communication error' });
          }
        }
        this.loading = false;
      },
      setShowFinalSubmit(proposal, sampleIndex, event) {
        this.finalSubmitProposalId = '';
        this.finalSubmitSampleIndex = '';
        if (event.target.id === this.submitBtnOptions.attempt.icon.id) {
          this.finalSubmitProposalId = proposal._id.$oid;
          if (this.isSynchrotron === false) {
            this.finalSubmitSampleIndex = sampleIndex;
          }
        }
      },
      async submitEvaluation(item, sample) {
        const sampleItem = this.isSynchrotron ? sample : sample.item;
        const sampleIndex = this.isSynchrotron ? 0 : sample.index;
        if (this.submittable(sampleItem)) {
          try {
            const response = await this.axios.patch(
              `/documents/${item._id.$oid}/${this.evaluationType}/${this.environment}`,
              { index: sampleIndex },
            );
            this.$notify({ type: 'success', title: response.data });
            const index = this.proposals.findIndex((el) => el._id.$oid === item._id.$oid);
            this.proposals[index].samples[sampleIndex][this.evaluation].states.name = 'submitted';
            if (this.environment === 'synchrotron') {
              this.$refs.asd_table.updateProposal(this.proposals[index]);
            }
          } catch (error) {
            if (error.response.data) {
              this.$notify({ type: 'error', title: error.response.data });
            } else {
              this.$notify({ type: 'error', title: 'Communication error' });
            }
          }
        }
      },
      submittable(sample) {
        const isDraft = this.get_deep_prop(sample, `${this.evaluation}.states.name`) === 'draft';
        const isStatusSet = !!this.get_deep_prop(sample, `${this.evaluation}.status`);
        if (!isDraft) {
          this.$notify({ type: 'error', title: 'Evaluation needs to be saved before submission!' });
          return isDraft;
        }
        if (!isStatusSet) {
          this.$notify({ type: 'error', title: 'Sample status needs to be set before submission!' });
          return isStatusSet;
        }
        return isDraft && isStatusSet;
      },
      updateProposal(proposal) {
        const index = this.proposals.findIndex((el) => el._id.$oid === proposal._id.$oid);
        if (index >= 0) {
          this.$set(this.proposals, index, proposal);
          this.$emit('refresh_proposals');
        }
      },
    },
  };
</script>

<style lang="scss"
       scoped>
  .card {
    margin: 4rem;
  }

  #filters {
    margin-top: 1rem;
  }

  .transparent-font {
    text-align: center;
  }

  .td-block {
    text-align: center;
    display: block !important;
  }

  .row {
    flex-wrap: inherit;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    text-align: center !important;
  }

  ::-moz-placeholder { /* Firefox 19+ */
    text-align: center !important;
  }

  :-ms-input-placeholder { /* IE 10+ */
    text-align: center !important;
  }

  :-moz-placeholder { /* Firefox 18- */
    text-align: center !important;
  }
</style>
