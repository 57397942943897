<template>
  <div>
    <component :is="evaluationFormComponentName"
               v-model="proposal"
               @closeModal="saveEvaluation"/>
  </div>
</template>

<script>
  import AdditionalSampleEvaluationRadiationForm
    from '@/components/evaluation/radiation/AdditionalSampleEvaluationRadiationForm';
  import AdditionalSampleEvaluationSafetyForm
    from '@/components/evaluation/safety/AdditionalSampleEvaluationSafetyForm';

  export default {
    name: 'AdditionalSampleEvaluationModal',
    components: {
      AdditionalSampleEvaluationRadiationForm,
      AdditionalSampleEvaluationSafetyForm,
    },
    props: {
      evaluationType: {
        type: String,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return { proposal: this.value };
    },
    computed: {
      evaluationFormComponentName() {
        return this.evaluationType === 'safety'
          ? 'AdditionalSampleEvaluationSafetyForm'
          : 'AdditionalSampleEvaluationRadiationForm';
      },
    },
    watch: {
      async value() {
        this.proposal = JSON.parse(JSON.stringify(this.value));
      },
    },
    methods: {
      async saveEvaluation() {
        const evaluationKey = `${this.evaluationType}_evaluation`;
        const evaluation = this.proposal.additional_sample_declaration[evaluationKey];
        try {
          const response = await this.axios.post(
            `/documents/${this.proposal._id.$oid}/${this.evaluationType}/synchrotron/additional_samples`,
            evaluation,
          );
          if (response.status === 200) {
            this.$set(evaluation, 'states', response.data.additional_sample_declaration[evaluationKey].states);
            this.$emit('input', this.proposal);
            this.$notify({ type: 'success', title: 'Saved successfully!' });
            this.close_modal_emit();
          } else {
            this.$notify({ type: 'error', title: 'Error during saving' });
          }
        } catch {
          this.$notify({ type: 'error', title: 'Communication error' });
        }
      },
    },
  };
</script>

<style scoped>
</style>
