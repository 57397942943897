<template>
  <div>
    <p class="p-form-label-with-margin-bold">Status</p>
    <div>
      <traffic-lights-buttons v-model="proposal.additional_sample_declaration.safety_evaluation.status"
                              :options="options"
                              labeled-lights
                              size="md"/>
    </div>
    <p class="p-form-label-with-margin-bold">
      Comments FOR USER regarding additional samples (also visible to Scientists)
    </p>
    <b-form-textarea id="commentsUser"
                     v-model="proposal.additional_sample_declaration.safety_evaluation.comments_user"
                     rows="5"/>
    <p class="p-form-label-with-margin-bold">Comments for Reviewers and Management</p>
    <b-form-textarea id="commentsReview"
                     v-model="proposal.samples[0].safety_evaluation.comments_review"
                     disabled="disabled"
                     rows="5"/>
    <p class="p-form-label-with-margin-bold">Comments for the User</p>
    <b-form-textarea id="commentsUserSamplesSafetyEvaluation"
                     v-model="proposal.samples[0].safety_evaluation.comments_user"
                     disabled="disabled"
                     rows="5"/>
    <b-button class="ma-1 mt-3 right"
              variant="info"
              @click="saveEvaluation">
      SAVE
    </b-button>
  </div>
</template>

<script>
  import { envMixin } from '@/mixins/environment';
  import trafficLightsOptions from '@/json/trafficLightsAcceptanceMarkSafety';
  import TrafficLightsButtons from '@/components/traffic_lights_buttons';

  export default {
    name: 'AdditionalSampleEvaluationSafetyForm',
    components: { TrafficLightsButtons },
    mixins: [envMixin],
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        options: trafficLightsOptions,
        proposal: JSON.parse(JSON.stringify(this.value)),
      };
    },
    methods: {
      saveEvaluation() {
        this.$emit('input', this.proposal);
        this.close_modal_emit();
      },
    },
  };
</script>

<style lang="scss"
       scoped>
</style>
