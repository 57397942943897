// COMMON DATA AND FUNCTIONS

const evaluationInAsd = {
  states: { name: '' },
  status: '',
  // eslint-disable-next-line camelcase
  comments_user: '',
};

function getFinalGradeRank(proposal) {
  return proposal.final_grade.states.name === 'submitted'
    ? proposal.final_grade.rank : '';
}

function getManagementMark(proposal) {
  return proposal.management_evaluation.states.name === 'submitted'
    ? proposal.management_evaluation.acceptance_mark : '';
}

function setRankInFinalGrade(proposals) {
  return proposals.map((proposal) => {
    if (proposal.final_grade === undefined) {
      return {
        ...proposal,
        // eslint-disable-next-line camelcase
        final_grade: { rank: '' },
      };
    }
    const { final_grade = {} } = proposal;
    // eslint-disable-next-line camelcase
    final_grade.rank = getFinalGradeRank(proposal);
    return {
      ...proposal,
      // eslint-disable-next-line camelcase
      final_grade,
    };
  });
}

function setAcceptanceMarkInManagementEvaluation(proposals) {
  return proposals.map((proposal) => {
    if (proposal.management_evaluation === undefined) {
      return {
        ...proposal,
        // eslint-disable-next-line camelcase
        management_evaluation: { acceptance_mark: '' },
      };
    }
    const { management_evaluation = {} } = proposal;
    // eslint-disable-next-line camelcase
    management_evaluation.acceptance_mark = getManagementMark(proposal);
    return {
      ...proposal,
      // eslint-disable-next-line camelcase
      management_evaluation,
    };
  });
}

function setTechnicalEvaluationData(proposals) {
  return proposals.map((proposal) => {
    const { technical_evaluation = {} } = proposal;
    // eslint-disable-next-line camelcase
    technical_evaluation.comments = technical_evaluation.states?.name === 'submitted'
      // eslint-disable-next-line camelcase
      ? technical_evaluation.comments
      : '';
    // eslint-disable-next-line camelcase
    return { ...proposal, technical_evaluation };
  });
}

// SAFETY_EVALUATION DATA AND FUNCTIONS

const additionalSampleEvaluationFieldsSafety = [
  {
    key: 'document_specification.document_id',
    label: 'ID',
    sortable: true,
  },
  {
    key: 'title',
    label: 'Title',
    sortable: true,
    thStyle: { width: '15%' },
  },
  {
    key: 'author',
    label: 'Author',
    sortable: true,
    tdClass: 'preLine',
  },
  {
    key: 'samples[0].safety_evaluation.status',
    label: 'Sample status',
    tdClass: 'text-center',
    sortable: true,
  },
  {
    key: 'additional_sample_declaration.safety_evaluation.status',
    label: 'ASD sample status',
    tdClass: 'text-center',
    sortable: true,
  },
  {
    key: 'additional_sample_declaration.safety_evaluation.states.name',
    label: 'ASD sample evaluation status',
    sortable: true,
  },
  {
    key: 'additional_sample_declaration.safety_evaluation.comments_user',
    label: 'ASD sample evaluation comments',
  },
  {
    key: 'actions',
    thStyle: { width: '10%' },
  },
];

const samplesInCericSafety = [{
  risk: '- (CERIC)',
  // eslint-disable-next-line camelcase
  sample_safety: ['- (CERIC)'],
  // eslint-disable-next-line camelcase
  safety_evaluation: { status: '- (CERIC)', states: { name: '- (CERIC)' } },
}];

function setSafetyEvaluationInSample(sample) {
  if (sample.safety_evaluation === undefined) {
    // eslint-disable-next-line camelcase
    return { ...sample, safety_evaluation: { states: {}, status: '' } };
  }
  if (sample.safety_evaluation.status === undefined
    || sample.safety_evaluation.status === null) {
    const { safety_evaluation = {} } = sample;
    // eslint-disable-next-line camelcase
    safety_evaluation.status = '';
    // eslint-disable-next-line camelcase
    return { ...sample, safety_evaluation };
  }
  return sample;
}

function setSafetyEvaluationInSamples(proposals, authors) {
  return proposals.map((proposal) => {
    // if proposal has samples i.e. is not CERIC
    if (Array.isArray(proposal.samples)) {
      return {
        ...proposal,
        samples: proposal.samples.map((sample) => setSafetyEvaluationInSample(sample)),
        author: `${authors[proposal._id.$oid].name}\n${authors[proposal._id.$oid].email}`,
      };
    }
    // if proposal is CERIC
    return {
      ...proposal,
      samples: samplesInCericSafety,
      author: `${authors[proposal._id.$oid].name}\n${authors[proposal._id.$oid].email}`,
    };
  });
}

function setSafetyEvaluationInAsd(proposals) {
  return proposals.map((proposal) => {
    if (proposal.additional_sample_declaration === undefined) {
      return {
        ...proposal,
        // eslint-disable-next-line camelcase
        additional_sample_declaration: { safety_evaluation: evaluationInAsd },
      };
    }
    if (proposal.additional_sample_declaration.safety_evaluation === undefined) {
      const { additional_sample_declaration = {} } = proposal;
      // eslint-disable-next-line camelcase
      additional_sample_declaration.safety_evaluation = evaluationInAsd;
      return {
        ...proposal,
        // eslint-disable-next-line camelcase
        additional_sample_declaration,
      };
    }
    return proposal;
  });
}

async function setDataInProposalsSafety(responseData, environment) {
  let proposals = responseData.documents;
  const { authors } = responseData;
  proposals = setSafetyEvaluationInSamples(proposals, authors);
  proposals = setTechnicalEvaluationData(proposals);
  if (environment === 'cryoem') {
    proposals = setRankInFinalGrade(proposals);
  } else {
    proposals = setAcceptanceMarkInManagementEvaluation(proposals);
    proposals = setSafetyEvaluationInAsd(proposals);
  }
  return proposals;
}

// RADIATION_EVALUATION DATA AND FUNCTIONS

const additionalSampleEvaluationFieldsRadiation = [
  {
    key: 'document_specification.document_id',
    label: 'ID',
    sortable: true,
  },
  {
    key: 'title',
    label: 'Title',
    sortable: true,
    thStyle: { width: '15%' },
  },
  {
    key: 'author',
    label: 'Author',
    sortable: true,
    tdClass: 'preLine',
  },
  {
    key: 'samples[0].radiation_evaluation.status',
    label: 'Sample status',
    tdClass: 'text-center',
    sortable: true,
  },
  {
    key: 'additional_sample_declaration.radiation_evaluation.status',
    label: 'ASD sample status',
    tdClass: 'text-center',
    sortable: true,
  },
  {
    key: 'additional_sample_declaration.radiation_evaluation.states.name',
    label: 'ASD sample evaluation status',
    sortable: true,
  },
  {
    key: 'additional_sample_declaration.radiation_evaluation.comments_user',
    label: 'ASD sample evaluation comments',
  },
  {
    key: 'actions',
    thStyle: { width: '10%' },
  },
];

const samplesInCericRadiation = [{
  risk: '- (CERIC)',
  // eslint-disable-next-line camelcase
  sample_safety: ['- (CERIC)'],
  // eslint-disable-next-line camelcase
  sample_safety_radioactive: false,
  // eslint-disable-next-line camelcase
  radiation_evaluation: { status: '- (CERIC)', states: { name: '- (CERIC)' } },
}];

function setRadiationEvaluationAndIsRadioactiveInSample(sample) {
  const { sample_safety = {} } = sample;
  // eslint-disable-next-line camelcase
  const isRadioactive = Array.isArray(sample_safety) ? sample_safety.includes('Radioactive') : false;
  if (sample.radiation_evaluation === undefined) {
    // eslint-disable-next-line camelcase
    return { ...sample, sample_safety_radioactive: isRadioactive, radiation_evaluation: { states: {}, status: '' } };
  }
  if (sample.radiation_evaluation.status === undefined
    || sample.radiation_evaluation.status === null) {
    const { radiation_evaluation = {} } = sample;
    // eslint-disable-next-line camelcase
    radiation_evaluation.status = '';
    // eslint-disable-next-line camelcase
    return { ...sample, sample_safety_radioactive: isRadioactive, radiation_evaluation };
  }
  // eslint-disable-next-line camelcase
  return { ...sample, sample_safety_radioactive: isRadioactive };
}

function setRadiationEvaluationInSample(sample, environment) {
  if (environment === 'cryoem') {
    if (sample.radiation_evaluation === undefined) {
      // eslint-disable-next-line camelcase
      return { ...sample, radiation_evaluation: { states: {}, status: '' } };
    }
    if (sample.radiation_evaluation.status === undefined
      || sample.radiation_evaluation.status === null) {
      const { radiation_evaluation = {} } = sample;
      // eslint-disable-next-line camelcase
      radiation_evaluation.status = '';
      // eslint-disable-next-line camelcase
      return { ...sample, radiation_evaluation };
    }
    return sample;
  }
  return setRadiationEvaluationAndIsRadioactiveInSample(sample);
}

function setRadiationEvaluationInSamples(proposals, authors, environment) {
  return proposals.map((proposal) => {
    // if proposal has samples i.e. is not CERIC
    if (Array.isArray(proposal.samples)) {
      return {
        ...proposal,
        samples: proposal.samples.map((sample) => setRadiationEvaluationInSample(sample, environment)),
        author: `${authors[proposal._id.$oid].name}\n${authors[proposal._id.$oid].email}`,
      };
    }
    // if proposal is CERIC
    return {
      ...proposal,
      samples: samplesInCericRadiation,
      author: `${authors[proposal._id.$oid].name}\n${authors[proposal._id.$oid].email}`,
    };
  });
}

function setRadiationEvaluationInAsd(proposals) {
  return proposals.map((proposal) => {
    if (proposal.additional_sample_declaration === undefined) {
      return {
        ...proposal,
        // eslint-disable-next-line camelcase
        additional_sample_declaration: { radiation_evaluation: evaluationInAsd },
      };
    }
    if (proposal.additional_sample_declaration.radiation_evaluation === undefined) {
      const { additional_sample_declaration = {} } = proposal;
      // eslint-disable-next-line camelcase
      additional_sample_declaration.radiation_evaluation = evaluationInAsd;
      return {
        ...proposal,
        // eslint-disable-next-line camelcase
        additional_sample_declaration,
      };
    }
    return proposal;
  });
}

async function setDataInProposalsRadiation(responseData, environment) {
  let proposals = responseData.documents;
  const { authors } = responseData;
  proposals = setRadiationEvaluationInSamples(proposals, authors, environment);
  proposals = setTechnicalEvaluationData(proposals);
  if (environment === 'cryoem') {
    proposals = setRankInFinalGrade(proposals);
  } else {
    proposals = setAcceptanceMarkInManagementEvaluation(proposals);
    proposals = setRadiationEvaluationInAsd(proposals);
  }
  return proposals;
}

export {
  additionalSampleEvaluationFieldsSafety,
  additionalSampleEvaluationFieldsRadiation,
  setDataInProposalsSafety,
  setDataInProposalsRadiation,
  setSafetyEvaluationInSamples,
  setRadiationEvaluationInSamples,
};
