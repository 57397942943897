<template>
  <div>
    <b-row align-h="between"
           class="container-fluid pb-3"
           no-gutters>

      <b-col cols="auto">
        <b-row id="filters">
          <b-col cols="auto">
            <b-button :pressed.sync="showFilter"
                      class="mb-2 mr-2">
              FILTER
            </b-button>
          </b-col>

          <b-col cols="auto">
            <keep-alive>
              <call-filter :id="id"
                           ref="call_filter"
                           v-model="proposals"
                           :loading="loading"
                           all-calls
                           @load_data="loadDataFor"
                           @selected_call_change="call_change"
                           @update_proposals="refresh_proposals_filter"/>
            </keep-alive>
          </b-col>

        </b-row>
      </b-col>
    </b-row>
    <b-table :current-page="currentPage"
             :fields="fields"
             :items="proposals"
             :per-page="perPage"
             head-variant="light"
             hover
             stacked="lg"
             striped
             @row-clicked="setShowFinalSubmit">

      <template v-slot:top-row="{fields}">
        <proposal-filter :id="id"
                         v-model="proposals"
                         :fields="fields"
                         :not_include_key="['actions', 'ordinalNumber']"
                         :selected_call="selectedCall"/>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage: perPage, currentPage: currentPage}"/>
      </template>

      <template v-slot:cell(samples[0].safety_evaluation.status)="row">
        <p v-if="isProposalCeric(row.item, evaluation)">
          {{ row.item.samples[0].safety_evaluation.status }}
        </p>
        <traffic-lights-buttons v-else
                                v-model="row.item.samples[0].safety_evaluation.status"
                                :options="trafficLightsOptions"
                                disabled
                                stacked/>
      </template>
      <template v-slot:cell(samples[0].radiation_evaluation.status)="row">
        <p v-if="isProposalCeric(row.item, evaluation)">
          {{ row.item.samples[0].radiation_evaluation.status }}
        </p>
        <traffic-lights-buttons v-else
                                v-model="row.item.samples[0].radiation_evaluation.status"
                                :options="trafficLightsOptions"
                                disabled
                                stacked/>
      </template>

      <template v-slot:cell(additional_sample_declaration.safety_evaluation.status)="row">
        <traffic-lights-buttons v-model="row.item.additional_sample_declaration.safety_evaluation.status"
                                :options="trafficLightsOptions"
                                disabled
                                stacked/>
      </template>
      <template v-slot:cell(additional_sample_declaration.radiation_evaluation.status)="row">
        <traffic-lights-buttons v-model="row.item.additional_sample_declaration.radiation_evaluation.status"
                                :options="trafficLightsOptions"
                                disabled
                                stacked/>
      </template>

      <template v-slot:cell(actions)="row">
        <div @click="setShowFinalSubmit(row.item, undefined, $event)"
             @keyup.down="setShowFinalSubmit(row.item, undefined, $event)">
          <action-download-additional-sample-declaration-pdf v-if="isAdditionalSampleDeclaration(row.item)"
                                                             :proposal="(row.item)"/>
          <base-icon-button-edit v-if="isEditionAllowed(row.item)"
                                 v-b-modal.additionalSampleEvaluationModal
                                 title="Evaluate additional samples"
                                 @click="setProposal(row.item)"/>

          <base-double-click-submit-button v-if="isEditionAllowed(row.item) && !submitting"
                                           :btn-options="submitBtnOptions"
                                           :is-confirmation="isFinalSubmit(row.item)"
                                           @click="submitEvaluation(row.item)"/>
        </div>
      </template>
    </b-table>

    <b-table-footer v-model="loadingFromProp"
                    :currentPage="currentPage"
                    :data_length="proposals.length"
                    :perPage="perPage"
                    @change_page="changePage"/>
    <b-modal id="additionalSampleEvaluationModal"
             :ref="modal_ref"
             bg-variant="light"
             header-bg-variant="dark"
             header-text-variant="white"
             hide-footer
             lazy
             size="lg"
             title="Additional Sample Declaration evaluation"
             @hidden="closeModal">
      <additional-sample-evaluation-modal v-model="proposal"
                                          :evaluationType="evaluationType"
                                          @closeModal="close_modal_slot"/>
    </b-modal>
  </div>
</template>

<script>
  import ActionDownloadAdditionalSampleDeclarationPdf
    from '@/components/actions/ActionDownloadAdditionalSampleDeclarationPdf';
  import AdditionalSampleEvaluationModal from '@/components/evaluation/AdditionalSampleEvaluationModal';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import BaseDoubleClickSubmitButton from '@/components/baseComponents/BaseDoubleClickSubmitButton';
  import BaseIconButtonEdit from '@/components/baseComponents/baseIcons/BaseIconButtonEdit';
  import bTableFooter from '@/components/b_table_footer';
  import { envMixin } from '@/mixins/environment';
  import { ordinalNumber } from '@/helpers/commonTableColumns';
  import proposalFilter from '@/components/proposal_filter';
  import TrafficLightsButtons from '@/components/traffic_lights_buttons';
  import submitBtnOptions from '@/json/doubleClickSubmitButton';
  import trafficLightsOptions from '@/json/trafficLightsAcceptanceMarkSafety';
  import callFilter from '@/components/call_filter';
  import {
    additionalSampleEvaluationFieldsSafety,
    additionalSampleEvaluationFieldsRadiation,
    setDataInProposalsSafety,
    setDataInProposalsRadiation,
  } from '@/helpers/safetyRadiationEvaluation';

  export default {
    name: 'AdditionalSampleEvaluationTab',
    components: {
      callFilter,
      ActionDownloadAdditionalSampleDeclarationPdf,
      AdditionalSampleEvaluationModal,
      BaseColumnOrdinalNumber,
      BaseDoubleClickSubmitButton,
      BaseIconButtonEdit,
      bTableFooter,
      proposalFilter,
      TrafficLightsButtons,
    },
    mixins: [envMixin],
    props: {
      evaluationType: {
        type: String,
        required: true,
      },
      isUserEditor: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        id: 1,
        loading: false,
        submitting: false,
        proposal: {},
        showFilter: true,
        currentPage: 1,
        perPage: 20,
        proposals: [],
        // eslint-disable-next-line camelcase
        modal_ref: 'modalRef',
        selectedCall: '',
        finalSubmit: '',
        submitBtnOptions,
        trafficLightsOptions,
      };
    },
    computed: {
      evaluation() {
        return `${this.evaluationType}_evaluation`;
      },
      fields() {
        const additionalFields = this.evaluationType === 'safety'
          ? additionalSampleEvaluationFieldsSafety : additionalSampleEvaluationFieldsRadiation;
        return [ordinalNumber, ...additionalFields];
      },
      loadingFromProp() {
        return this.loading;
      },
    },
    watch: {
      showFilter() {
        this.$emit('toggle_show');
      },
    },
    methods: {
      changePage(val) {
        this.currentPage = val;
      },
      closeModal() {
        const index = this.proposals.findIndex((el) => el._id.$oid === this.proposal._id.$oid);
        if (index >= 0) {
          this.$set(this.proposals, index, this.proposal);
          this.$emit('update-proposal', this.proposal);
        }
      },
      editable(proposal) {
        if (proposal.additional_sample_declaration?.[this.evaluation]) {
          if (proposal.additional_sample_declaration[this.evaluation].states) {
            const values = ['submitted', 'deleted'];
            return (!values.includes(proposal.additional_sample_declaration[this.evaluation].states.name));
          }
        }
        return true;
      },
      isAdditionalSampleDeclaration(item) {
        return item.additional_sample_declaration?.states?.name === 'submitted';
      },
      isEditionAllowed(proposal) {
        if (!this.isUserEditor) return false;
        return this.editable(proposal);
      },
      isFinalSubmit(proposal) {
        return proposal._id.$oid === this.finalSubmit;
      },
      isProposalCeric(proposal, evaluation) {
        return proposal.samples[0][evaluation].status === '- (CERIC)';
      },
      async loadDataFor(call) {
        const callParam = { call };
        this.loading = true;
        try {
          const response = await this.axios.get(
            `/documents/${this.evaluationType}/${this.environment}`,
            { params: callParam },
          );
          if (response.status !== 204) {
            this.proposals = this.evaluationType === 'safety'
              ? await setDataInProposalsSafety(response.data, this.environment)
              : await setDataInProposalsRadiation(response.data, this.environment);
            this.proposals = this.proposals.filter(
              (el) => el.additional_sample_declaration?.states?.name === 'submitted',
            );
          } else {
            this.proposals = [];
          }
          // update data in proposal-filter, call-filter component
          this.$emit('setup_proposal', this.proposals);
        } catch (error) {
          if (error.response.data) {
            this.$notify({ type: 'error', title: error.response.data });
          } else {
            this.$notify({ type: 'error', title: 'Communication error' });
          }
        }
        this.loading = false;
      },
      setProposal(proposal) {
        this.proposal = proposal;
      },
      setShowFinalSubmit(proposal, unusedIndex, event) {
        this.finalSubmit = '';
        if (event.target.id === this.submitBtnOptions.attempt.icon.id) {
          this.finalSubmit = proposal._id.$oid;
        }
      },
      async submitEvaluation(item) {
        this.submitting = true;
        try {
          const response = await this.axios.patch(
            `/documents/${item._id.$oid}/${this.evaluationType}/synchrotron/additional_samples`,
          );
          // eslint-disable-next-line no-param-reassign
          item.additional_sample_declaration[this.evaluation].states.name = 'submitted';
          const index = this.proposals.findIndex((el) => el._id.$oid === item._id.$oid);
          if (index >= 0) {
            this.$notify({ type: 'success', title: response.data });
            this.$set(this.proposals, index, item);
            this.$emit('update-proposal', item);
          }
        } catch (error) {
          if (error.response.data) {
            this.$notify({ type: 'error', title: error.response.data });
          } else {
            this.$notify({ type: 'error', title: 'Communication error' });
          }
        }
        this.submitting = false;
      },
      updateProposal(proposal) {
        const index = this.proposals.findIndex((el) => el._id.$oid === proposal._id.$oid);
        if (index >= 0) {
          this.$set(this.proposals, index, proposal);
          this.$emit('refresh_proposals');
        }
      },
    },
  };

</script>

<style scoped>
</style>
