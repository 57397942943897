<template>
  <div>
    <b-table :fields="fields"
             :items="[sample]"
             bordered
             class="table-responsive"
             head-variant="light"
             responsive
             stacked
             striped>
      <template v-slot:cell(sample_safety)="row">
        {{ row.item.sample_safety.join(', ') }}
      </template>
    </b-table>
    <safety-form v-if="isSafetyEvaluation"
                 v-model="sample.safety_evaluation"
                 :proposal-id="proposalId"
                 :sample-index="sampleIndex"
                 @closeModal="close_modal_emit"/>
    <radiation-form v-else
                    v-model="sample.radiation_evaluation"
                    :proposal-id="proposalId"
                    :sample-index="sampleIndex"
                    @closeModal="close_modal_emit"/>
  </div>
</template>

<script>
  import RadiationForm from '@/components/evaluation/radiation/RadiationForm';
  import SafetyForm from '@/components/evaluation/safety/SafetyForm';

  export default {
    name: 'SafetyRadiationModal',
    components: {
      RadiationForm,
      SafetyForm,
    },
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
      isSafetyEvaluation: {
        type: Boolean,
        required: true,
      },
      proposalId: {
        type: String,
        required: true,
      },
      sampleIndex: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return { sample: this.value };
    },
    watch: {
      sample: {
        handler() {
          this.$emit('input', this.sample);
        },
        deep: true,
      },
      value() {
        this.sample = this.value;
      },
    },
  };
</script>

<style scoped>

</style>
